export default {
  "language:en": "Anglais",
  "language:fr": "Français",
  "language:es": "Espagnol",
  "env:dev": "Version de dévelopement",
  "env:test": "Version de test",
  "global:loading": "Chargement en cours...",
  "global:pleasewait": "Veuillez patienter...",
  "global:upload": "Téléverser des fichiers",
  "global:items": "éléments",
  "global:item": "élément",
  "global:next": "Suivant",
  "menu:logout": "Déconnexion",
  "logout:message": "Déconnexion en cours...",
  "maintenance:message":
    "Attention! Une maintenance de la plateforme AMIGO aura lieu entre {0} and {1}.",
  "global:yearsold": "ans",
  "menu:newmessages": "nouveaux messages",
  "menu:newmessages:bubble":
    "Vous avez {0} nouveaux messages dans votre boîte de réception AMIGO. Consultez les afin de rester informer sur les derniers évemenents relatifs à votre compte.",
  "menu:newmessages:bubble:title": "Nouveaux messages",
  "global:moredetails": "Plus de détails",
  "creditcard:validplaceholder": "expiration",
  "creditcard:nameplaceholder": "VOTRE NOM ICI",
  "chat:panel:title": "Clavardez avec votre agent",
  "chat:notification:message":
    "Vous avez reçu un nouveau message instantanné de votre agent.",
  "chat:notification:ack": "Clavardez avec votre agent",
  "chat:title": "Chat",
  "navbarfull:message:title": "Messagerie",
  "navbarfull:enterprise:selector:title": "Sélecteur d'entreprise",
  "navbarfull:language:selector:title": "Sélecteur de langue",
  "navbarfull:profile:title": "Profil",
  "address:street": "Rue",
  "address:street:error": "La rue est requise.",
  "address:city": "Ville",
  "address:city:error": "La ville est requise.",
  "address:postalcode": "Code Postal",
  "address:postalcode:error": "Le code postal est requis.",
  "address:state": "State",
  "address:state:error": "La province est requise.",
  "owner:ask":"Propriétaire",
  "address:askowner:error": "L'information sur le propriétaire est requise.",
  "menu:alerts": "Alertes",
  "menu:enterprises": "Entreprise",
  "menu:requests": "Demandes",
  "menu:immigration": "Immigration",
  "menu:participants": "Participants",
  "menu:billing": "Facturation",
  "menu:factures": "Facturation",
  "menu:transports": "Transports",
  "menu:documents": "Infos",
  "menu:messages": "Messagerie",
  "menu:profile": "Profil",
  "menu:expand": "Étendre le menu de navigation",
  "menu:collapse": "Réduire le menu de navigation",

  "login:title": "AMIGO - par FERME Québec",
  "login:instructions":
    "Entrez vos informations d'identification pour vous connecter.",
  "login:username": "Nom d'utilisateur",
  "login:password": "Mot de passe",
  "login:logincommand": "Se connecter",
  "login:forgotinscructions": "Vous avez oublié votre mot de passe?",
  "login:linkprefix": "Pas de problème, cliquez ",
  "login:linkhere": "ici",
  "login:linksuffix": "pour le réinitialiser.",
  "login:error":
    "Nom d'utilisateur ou mot de passe invalide. Veuillez vérifier vos informations.",
  "login:error:locked":
    "Votre compte est présentement verrouillé. Veuillez contacter votre administrateur.",
  "login:username:error": "Le nom d'utilisateur est requis.",
  "login:password:error": "Le mot de passe est requis.",

  "forgot:title": "AMIGO - par FERME Québec<br />J'ai oublié mon mot de passe",
  "forgot:instructions":
    "Veuillez inscrire votre nom d'utilisateur ou votre adresse de courriel.",
  "forgot:username": "Nom d'utilisateur ou courriel",
  "forgot:forgotcommand": "Soumettre",
  "forgot:cancelcommand": "Annuler",
  "forgot:back": "Retour",
  "requests:positions": "Postes",
  "forgot:error":
    "Nous n'avons pas pu vous envoyer de courriel. Veuillez contacter votre administrateur.",
  "forgot:success":
    "Nous avons envoyé un courriel à votre compte afin d'amorcer la procédure de réinitialisation de mot de passe.",
  "forgot:username:error": "Le nom d'utilisateur ou courriel est requis.",

  "reset:title": "AMIGO - par FERME Québec   Réinitialisation du mot de passe",
  "reset:instructions": "Veuillez choisir un nouveau mot de passe.",
  "reset:newpassword": "Nouveau mot de passe",
  "reset:confirmpassword": "Confirmation du mot de passe",
  "reset:resetcommand": "Réinitialiser",
  "reset:cancelcommand": "Annuler",
  "reset:back": "Retour",
  "reset:error":
    "Nous n'avons pu réinitialiser votre mot de passe. Veuillez contacter votre administrateur.",
  "reset:success":
    "Votre mot de passe a été correctement réinitialiser. Vous pouvez procéder à la page de connection.",
  "reset:newpassword:error": "Le nouveau mot de passe est requis.",
  "reset:newpassword:strength": "Ce mot de passe est trop facile.",
  "reset:confirmpassword:error":
    "Vous devez confirmer votre nouveau mot de passe.",
  "reset:confirmpassword:mismatch": "Le mot de passe ne correspond pas.",

  "passwordstrength:0": "_",
  "passwordstrength:1": "Trop court",
  "passwordstrength:2": "Trop commun",
  "passwordstrength:3": "Faible",
  "passwordstrength:4": "Ok",
  "passwordstrength:5": "Fort",

  "changepwd:title": "Changer votre mot de passe",
  "changepwd:instructions":
    "Entrez votre mot de passe courant et un nouveau mot de passe, confirmez-le et appuyer sur soummettre.",
  "changepwd:currentpassword": "Mot de passe courant",
  "changepwd:newpassword": "Nouveau mot de passe",
  "changepwd:confirmpassword": "Confirmer le nouveau mot de passe",
  "changepwd:submitcommand": "Soumettre",
  "changepwd:cancelcommand": "Annuler",
  "changepwd:back": "Ok",
  "changepwd:error":
    "Une erreur est survenue lors de votre changement de mot de passe. Vérifier votre mot de passe courant.",
  "changepwd:success": "Votre mot de passe a été changer avec succès.",
  "changepwd:lastreset": "Dernier changement de mot de passe:",
  "changepwd:currentpassword:error": "Votre mot de passe courant est requis.",
  "changepwd:newpassword:error": "Le nouveau mot de passe est requis.",
  "changepwd:confirmpassword:error":
    "Vous devez confirmer votre nouveau mot de passe.",
  "changepwd:newpassword:strength": "Ce mot de passe est trop facile.",
  "changepwd:confirmpassword:mismatch": "Le mot de passe ne correspond pas.",
  "global:never": "Jamais",
  "alert:element": "Alerte",
  "alerts:affected": "Les éléments suivants sont touchés par cette alerte:",
  "alerts:deletetitle": "Suppression des alertes",
  "alerts:delete": "Supprimer",
  "alerts:deletemessage:plural":
    "Êtes-vous sûr de vouloir supprimer ces alertes?",
  "alerts:deletemessage:simple":
    "Êtes-vous sûr de vouloir supprimer cette alerte?",
  "alerts:title": "Titre",
  "alerts:type": "Type d'alerte",
  "alerts:createdon": "Créée le",
  "alerts:createdon:filter": "Créer",
  "alerts:seenOn": "Lue le",
  "alerts:description": "Description",
  "alerts:comments": "Commentaires",
  "alerts:defaultgroup": "Élements",
  "alerts:defaultgroup:_t": "Élements",
  "alerts:defaultgroup:_d": "Demandes",
  "alerts:defaultgroup:_m": "Messages",
  "alerts:defaultgroup:_f": "Facturation",
  "alerts:status": "Statut",
  "alerts:all": "Toutes les alertes",
  "alerts:unread": "Non lues",
  "alerts:newalert": "Alerte non lue",
  "alerts:spotlight:intro":
    "Nous attirons votre attention sur cette alerte importante",

  "profile:info": "Infos",
  "profile:name": "Nom",
  "profile:username": "Nom d'utilisateur",
  "profile:email": "Courriel",
  "profile:language": "Langue",
  "profile:communications:preferences:title": "Préférences de communication",
  "profile:preferences:success":
    "Vos préférences de communication ont été mis à jour avec succès.",
  "profile:preferences:instuctions":
    "Vous pouvez choisir ici la méthode de communication qui vous convient le mieux lorsque nous vous envoyons des notifications.",
  "profile:communications:methods": "Méthodes de communication",
  "profile:communications:methods:placeholder": "Méthode",

  "profile:communications:alerts": "Notifications des alertes non lues",
  "profile:communications:chat":
    "Notifications des messages instantanés non lus",

  "enterprise:tab:enterprise": "Entreprise",
  "enterprise:tab:documents": "Documents",
  "enterprise:tab:payments": "Informations de paiement",
  "enterprise:address:enterprise": "Entreprise",
  "enterprise:address:name": "Nom",
  "enterprise:contacts": "Contacts",
  "enterprise:contacts:submit": "Modification de contacts",
  "enterprise:info:phone": "Téléphone",
  "enterprise:info:cellphone": "Cellulaire",
  "enterprise:info:fax": "Télécopieur",
  "enterprise:info:email": "Courriel",
  "enterprise:info:neq": "N.E.Q",
  "enterprise:info:tps": "T.P.S",
  "enterprise:info:sector": "Secteur",
  "enterprise:info:subsector": "Sous-secteur",
  "enterprise:info:memberid": "Numéro de membre",
  "enterprise:addresses:title": "Adresses",
  "enterprise:addresses:name": "Nom de l'entreprise",
  "enterprise:addresses:branches": "Établissements",
  "enterprise:addresses:address": "Adresse",
  "enterprise:addresses:mainaddress": "Adresse principale",
  "enterprises:contacts:newsletter": "Abonné à l'infolettre",
  "enterprises:contacts:billing": "Contact de facturation",
  "enterprises:contacts:request": "Contact de demande",
  "enterprises:contacts:ferme": "Contact FERME/Voyages",
  "enterprises:contacts:new": "Nouvelle demande de contact",
  "enterprises:contacts:firstname:error": "Le prénom est requis.",
  "enterprises:contacts:lastname:error": "Le nom est requis.",
  "enterprises:contacts:email:error": "Un courriel valide est requis.",
  "enterprises:contacts:firstname": "Prénom",
  "enterprises:contacts:lastname": "Nom",
  "enterprises:contacts:phone": "Téléphone",
  "enterprises:contacts:cellphone": "Cellulaire",
  "enterprises:contacts:email": "Courriel",
  "enterprises:contacts:title": "Titre",
  "enterprises:contacts:panel": "Demande de modification de contacts",
  "enterprises:documents:more": "Autres documents",
  "enterprises:documents:empty": "Aucun document disponible",
  "enterprises:documents:processedon": "Traité le",
  "enterprises:documents:noprocess": "Non traité",
  "enterprises:contacts:delete": "Supprimer ce contact",
  "enterprises:contacts:delete:confirm":
    "Êtes-vous certain de vouloir supprimer ce contact?",
  "enterprises:contacts:undo": "Annuler cette modification",

  "enterprises:paymentmethods:authorize:transport":
    "J'autorise FERME/Voyages à utiliser cette carte de crédit pour compléter une transaction d'achat de billets d'avion. Il est recommandé de cocher cette case afin d'éviter de retarder l'arrivée ou le départ de vos travailleurs.",
  "enterprises:paymentmethods:authorize:accept":
    "J'ai lu et j'accepte ces conditions",
  "enterprises:paymentmethods:authorize:billing":
    "J'autorise FERME à utiliser cette carte de crédit sans autre avis pour effectuer le paiement des services suivants : cotisation annuelle, frais de gestion, frais d'administration, frais de retard, billets d'avion et frais de billetterie.",
  "enterprises:paymentmethods:authorize:error":
    "Cette autorisation est requise",
  "enterprises:paymentmethods:createdon": "Créée le",
  "enterprises:paymentmethods:createdby": "Créer par",
  "enterprises:paymentmethods:card": "Numéro de la carte",
  "enterprises:paymentmethods:name": "Nom sur la carte",
  "enterprises:paymentmethods:type": "Type",
  "enterprises:paymentmethods:element": "Méthode de paiement",
  "enterprises:paymentmethods:header": "Informations de paiement",
  "enterprises:paymentmethods:new": "Nouveau",
  "enterprises:paymentmethods:delete": "Supprimer",
  "enterprises:paymentmethods:cancel": "Annuler",
  "enterprises:paymentmethods:save": "Enregistrer",
  "enterprises:paymentmethods:saving":
    "Veuillez patientez... Nous ajoutons votre nouvelle méthode de paiement.",
  "enterprises:paymentmethods:expiration": "Expiration",
  "enterprises:paymentmethods:cvc": "CVC",
  "enterprises:paymentmethods:deletetitle":
    "Suppression des méthodes de paiement",
  "enterprises:paymentmethods:deletemessage:plural":
    "Êtes-vous sûr de vouloir supprimer ces méthodes de paiement?",
  "enterprises:paymentmethods:deletemessage:simple":
    "Êtes-vous sûr de vouloir supprimer cette méthode de paiement?",
  "enterprises:paymentmethods:save:error":
    "Une error nous empêche de sauvegarder cette nouvelle méthode de paiement.",
  "enterprises:paymentmethods:new:header": "Nouvelle méthode de paiement",
  "enterprises:neq:editBouton:title": "Éditer",
  "enterprises:neq:saveButton:title": "Sauvegarder",
  "enterprises:neq:cancelButton:title": "Annuler",
  "enterprises:paymentmethods:card:error":
    "Le numéro de la carte est invalide.",
  "enterprises:paymentmethods:name:error":
    "Le nom du détenteur de la carte n'est pas correctement défini ou contient des caractères non supportés (seul les lettres, les chiffres, les espaces et les caractères suivants : _-:.@$=/.,).",
  "enterprises:paymentmethods:cvc:error": "Le numéro CVC est requis.",
  "enterprises:paymentmethods:cvc:label": "Code de vérification",
  "enterprises:paymentmethods:month": "Mois",
  "enterprises:paymentmethods:year": "Année",
  "enterprises:paymentmethods:expiry:error":
    "La date d'expiration n'est pas valide",
  "enterprises:paymentmethods:delete:irreversible":
    "Cette opération est irréversible.",
  "enterprises:paymentmethods:delete:lastcard:1":
    "ATTENTION ! Vous êtes sur le point de retirer la dernière carte de crédit active dans votre dossier. En l'absence de carte de crédit, il se peut que des transactions d'achat de billets d'avion soient retardées.",
  "enterprises:paymentmethods:delete:lastcard:2":
    "Souhaitez-vous néanmoins retirer cette carte de crédit ?",
  "enterprises:alerts:title": "Niveau d'alertes",
  "enterprises:alerts:label":
    "Délai d'alertage en jours avant les arrivées et les départs",
  "enterprises:alerts:instructions":
    "Je veux recevoir des alertes avant les arrivées ou les départs.",
  "enterprises:alerts:tooltip":
    "Vous receverez des alertes {0} jours avant un arrivée ou un départ.",
  "enterprises:contacts:added": "Contacts à ajouter",
  "enterprises:contacts:modified": "Contacts en modification",
  "enterprises:contacts:deleted": "Contacts à supprimer",
  "enterprises:contacts:apply:1": "Soumettre",
  "enterprises:contacts:apply:2": "Soumettre",
  "enterprises:contacts:confirm":
    "Êtes-vous sûr de vouloir soumettre cette demande de modification de contacts?",
  "enterprises:contacts:error":
    "Désolé. Une erreur est survenue lors de la soumission de vos demandes de modification de contacts.",

  "documents:name": "Nom du document",
  "documents:size": "Taille",
  "documents:createdBy": "Créer Par",
  "documents:createdOn": "Créée le",
  "documents:lastmodified": "Dernière modification le",
  "documents:processedOn": "Traitée Le",
  "documents:download": "Télécharger",
  "documents:download:progress": "Téléchargement en cours...",
  "documents:formulaire": "Formulaires",
  "documents:capsuleaide": "Capsule d'aide",
  "documents:aidememoire": "Aide-Mémoire",
  "documents:backcategories": "Retour aux catégories",
  document: "Document",
  "documents:download:error":
    "Désolé, nous ne pouvons pas télécharger votre document pour le moment.",

  "participants:info": "Informations personnelles",
  "participants:workinfo": "Informations professionelles",
  "participants:name": "Nom complet",
  "participants:name:lastname": "Nom",
  "participants:name:firstname": "Prénom",
  "participants:createdon": "Créée le",
  "participants:country": "Pays",
  "participants:birthdate": "Date de naissance",
  "participants:birthdate:filter": "Né(e)",
  "participants:age": "Âge",
  "participants:code": "Code",
  "participants:genre": "Genre",
  "participants:status": "Statut",
  "participants:insurance": "Code d'assurance",
  "participants:marks": "Caractéristiques physiques",
  "participants:modifiedon": "Modifié le",
  "participants:recall": "Statut du dernier rappel",
  "participants:reason": "Raison",
  "participants:address": "Adresse du participant",
  "participants:edit": "Modifier",
  "participants:participant": "Participant",
  "participants:save": "Sauvegarder",
  "participants:cancel": "Annuler",
  "participants:street": "Rue",
  "participants:city": "Ville",
  "participants:postal": "Code postal",
  "participants:state": "Province/État",
  "participants:email": "Courriel",
  "participants:phone": "Téléphone",
  "participants:cellphone": "Cellulaire",
  "participants:back": "Retour à la liste",
  "participants:upload": "Téléverser des fichiers",
  "participants:error:0":
    "Une erreur s'est produite lors de la sauvegarde de l'adresse du participant. Veuillez contactez votre administrateur.",
  "participants:documents": "Documents",
  "participants:history": "Historique",
  "participants:positions": "Postes",
  "participants:positions:requestnumber": "Numéro de demande",
  "participants:positions:title": "Titre du poste",
  "participants:history:recall": "Statut du rappel",
  "participants:history:endParticipation": "Type de fin de participation",
  "participants:positions:enterpriseName": "Nom de l'employeur",
  "participants:positions:arrivaldate": "Date d'arrivée",
  "participants:positions:departuredate": "Date de départ",
  "participants:positions:nextevent": "Prochain événement",
  "participants:history:title": "Titre du poste",
  "participants:history:enterpriseName": "Nom de l'employeur",
  "participants:history:arrivaldate": "Date d'arrivée",
  "participants:history:departuredate": "Date de départ",
  "flights:flightdate":
    "Choisir une date de vol située entre ces deux dates de calendrier",
  "billing:status:paid": "Payée",
  "billing:status:late": "Retard",
  "billing:status:pending": "À payer",
  "billing:status:partial": "Payée partiellement",
  "billing:billingnumber": "Numéro de facture",
  "billing:billingdate": "Date de facturation",
  "billing:subtotal": "Sous-total",
  "billing:tps": "T.P.S",
  "billing:tvq": "T.V.Q",
  "billing:total": "Total",
  "billing:balance": "Solde",
  "billing:amount": "Versement",
  "billing:status": "Statut",
  "billing:element": "Facture",
  "billing:payment:element": "Paiement",
  "billing:payment:details": "Détails du paiement",
  "billing:payments:paymentnumber": "Numéro de paiement",
  "billing:payments:paymentdate": "Date de paiement",
  "billing:payments:bills": "Factures",
  "billing:payments:paymenttype": "Type de paiement",
  "billing:payments:confirmation": "Numéro de confirmation",
  "billing:payments:confirmation:short": "# confirmation",
  "billing:payments:total": "Montant",
  "billing:billing:header": "Factures",
  "billing:payments:header": "Paiements",
  "billing:payments:retrypaymentagain": "Ré-essayer de nouveau",
  "billing:payments:pendingbill:date": "Date",
  "billing:payments:pendingbill:total": "Total",
  "billing:payments:pendingbill:received": "Paiement reçu",
  "billing:payments:pendingbill:payment": "Versement",
  "billing:payments:pendingbill:balance": "Solde",
  "billing:payments:pendingbill:newbalance": "Nouveau solde",
  "billing:payments:pendingbill:bill": "Facture",
  "billing:billtype": "Type",
  "billing:billtype:standard": "Facture générée par FERME",
  "billing:billtype:travel": "Facture générée par FERME/Voyages",
  "billing:warning:1":
    "Les factures de FERME/Voyages ne sont pas payables par le portail AMIGO. Vous devez donc communiquer avec notre service de la comptabilité (514 387-8816 # 221) afin de procéder au paiement.",
  "billing:legend": "Légende",
  "billing:accountstatement": "État de compte",
  "billing:printbill": "Imprimer",
  "billing:backtoalert": "Revenir à l'alerte visité",
  "billing:payments:seebills": "Voir les factures",
  "billing:payments:seepayments": "Voir les paiements",
  "billing:payments:newpayment": "Nouveau paiement",
  "billing:payments:newpayment:existing":
    "Utiliser une méthode de paiement au dossier",
  "billing:payments:newpayment:existing:required":
    "Veuillez choisir une méthode de paiements parmi les options ci-dessous.",
  "billing:payments:newpayment:newcard":
    "Utiliser une autre méthode de paiement",
  "billing:payments:newpaymentagain": "Ajouter un autre paiement",
  "billing:payments:newpayment:confirm": "Veuillez confirmer votre paiement.",
  "billing:payments:newpayment:confirm:instructions":
    "Un paiement au montant de CAD {0} $ sera porté à la carte de crédit ci-dessous.",
  "billing:payments:thankyou": "Merci pour votre paiement!",
  "billing:payments:newpayment:amountrequired:singular":
    "Veuillez entrez un montant comme versement votre facture à payer.",
  "billing:payments:newpayment:amountrequired:plural":
    "Veuillez entrez un montant comme versement pour au moins une facture à payer.",

  "billing:payments:confirmation:date": "Date de paiement",
  "billing:payments:confirmation:auth": "Numéro de confirmation",
  "billing:payments:confirmation:receipt": "Numéro de reçu",
  "billing:payments:confirmation:transaction": "Numéro de transaction",
  "billing:payments:confirmation:conf": "Numéro de réference",
  "billing:payments:confirmation:card": "Numéro de carte",
  "billing:payments:paymenterror:title": "Erreur de transaction de paiement",
  "billing:payments:paymenterror:refused": "Paiment refusé",
  "billing:payments:paymenterror:seats_exeeded":
    "Désolé, le nombre de places demandées n'est plus disponible. Veuillez revoir votre sélection ou changer votre itinéraire.",
  "billing:payments:paymenterror:participants":
    "Les participants suivants ne peuvent être inclus dans votre réservation:",
  "billing:payments:reservationerror:title":
    "Erreur de transaction de votre réservation",
  "billing:payments:employer": "Employeur",
  "billing:payments:newbillnumber:label": "Numéro du paiement",
  "billing:payments:newbillnumber":
    "Le numéro du paiement<br />sera généré à la sauvegarde.",
  "billing:payments:newbillnumber:delayed":
    "Une facture FERME/Voyages vous<br />sera émise selon les procédures<br />habituelles.",
  "transports:flights:backhome": "Terminé",
  "transports:flights:pnr": "Numéro de réservation",
  "transports:flights:pnr:internal": "Interne",
  "transports:flights:notfound":
    "Oups... Aucun vol n'est disponible selon vos critères de recherche.",
  "transports:flights:confirmation:header":
    "Merci d'avoir effectué votre réservation avec FERME/Voyages.",
  "transports:flights:confirmation:text":
    "Confirmation de la réservations des vols",
  "billing:payments:processing":
    "Nous procédons au traitement de votre réservation. Veuillez patientez",
  "billing:payments:paymenterror:FLIGHT":
    "Désolé, une erreur est survenue lors du traitement de votre réservation. Veuillez contactez votre administrateur.",
  "billing:payments:paymenterror:000":
    "Une erreur est survenue lors du traitement de votre paiement. Veuillez contactez votre administrateur.",
  "billing:payments:paymenterror:050":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 050",
  "billing:payments:paymenterror:051":
    "Votre carte de crédit est expirée. Code: 051",
  "billing:payments:paymenterror:052":
    "Le nombre de tentatives échouées est trop élevées. Code: 052",
  "billing:payments:paymenterror:053":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 053",
  "billing:payments:paymenterror:054":
    "Cette transaction ne peut être traitée avec cette carte de crédit. Code: 054",
  "billing:payments:paymenterror:055":
    "Votre carte de crédit a été rejetée pour cette transaction. Code: 055",
  "billing:payments:paymenterror:056":
    "Votre carte de crédit n'est pas supportée par notre fournisseur de paiement. Code: 056",
  "billing:payments:paymenterror:057":
    "Cette carte de crédit a été rapportée comme perdue ou volée et ne peut donc pas être utilisée afin de compléter cette transaction. Veuillez contactez votre émetteur de carte. Code: 057",
  "billing:payments:paymenterror:058":
    "L'utilisation de votre carte de crédit a été restreinte et ne peut donc pas être utilisée afin de compléter cette transaction. Veuillez contactez votre émetteur de carte. Code: 058",
  "billing:payments:paymenterror:059":
    "L'utilisation de votre carte de crédit a été restreinte et ne peut donc pas être utilisée afin de compléter cette transaction. Veuillez contactez votre émetteur de carte. Code: 059",
  "billing:payments:paymenterror:060":
    "L'utilisation de votre carte de crédit a été restreinte et ne peut donc pas être utilisée afin de compléter cette transaction. Veuillez contactez votre émetteur de carte. Code: 060",
  "billing:payments:paymenterror:061":
    "L'utilisation de votre carte de crédit a été restreinte et ne peut donc pas être utilisée afin de compléter cette transaction. Veuillez contactez votre émetteur de carte. Code: 061",
  "billing:payments:paymenterror:062":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 062",
  "billing:payments:paymenterror:063":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 063",
  "billing:payments:paymenterror:064":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 064",
  "billing:payments:paymenterror:065":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 065",
  "billing:payments:paymenterror:066":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 066",
  "billing:payments:paymenterror:067":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 067",
  "billing:payments:paymenterror:068":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 068",
  "billing:payments:paymenterror:069":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 069",
  "billing:payments:paymenterror:070":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 070",
  "billing:payments:paymenterror:071":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 071",
  "billing:payments:paymenterror:072":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 072",
  "billing:payments:paymenterror:073":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 073",
  "billing:payments:paymenterror:074":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 074",
  "billing:payments:paymenterror:075":
    "Une erreur est survenue lors du traitement de votre paiement. Code: 075",
  "billing:payments:paymenterror:076":
    "Votre carte de crédit ne possède pas les fonds nécessaires pour ce paiement. Votre transaction a été refusée. Code: 076",
  "billing:payments:paymenterror:095":
    "Votre carte de crédit ne possède pas les fonds nécessaires pour ce paiement. Votre transaction a été refusée. Code: 095",
  "billing:payments:paymenterror:086":
    "Votre paiement ne respecte pas la limite minimal de paiement. Votre transaction a été refusée. Code: 086",
  "billing:payments:paymenterror:476":
    "Une erreur est survenue lors du traitement de votre paiement. Veuillez contactez votre administrateur. Code: 476",

  "billing:payments:submit": "Payer",
  "billing:payments:cancel": "Annuler",

  "billing:pendingbills:billsummary:title": "Sommaire de la facturation",
  "billing:payments:confirmpay": "Confirmer le paiement",
  "billing:pendingbills:billsummary:subtotal": "Sous-total",
  "billing:pendingbills:billsummary:tps": "T.P.S",
  "billing:pendingbills:billsummary:tvq": "T.V.Q",
  "billing:pendingbills:billsummary:total": "Total",
  "billing:payments:securedialog:instructions":
    "Afin de procéder au paiement il vous faut introduire le numéro de vérificiation de carte qui ce trouve à l'endos de celle-ci.",
  "billing:payments:securedialog:cvc:label": "Numéro de vérification de carte",
  "billing:payments:securedialog:error":
    "Veuillez entrer le code de vérification de la carte.",
  "billing:payments:back": "Retour à la liste",

  "dossierimmigration:info": "Infos",
  "dossierimmigration:file": "Dossier d'immigration",
  "dossierimmigration:year": "Année",
  "dossierimmigration:number": "Numéro",
  "dossierimmigration:agent": "Agent",
  "dossierimmigration:title": "Titre",
  "dossierimmigration:country": "Pays",
  "dossierimmigration:version": "Version",
  "dossierimmigration:filecreation": "Création de dossier",
  "dossierimmigration:version:title": "Titre",
  "dossierimmigration:namesreception": "Date de réception des noms",
  "dossierimmigration:namesrequest": "Date de demande des noms",
  "dossierimmigration:transmission": "Date de transmission",
  "dossierimmigration:request": "Demande",
  "dossierimmigration:participantCount": "Nombre de travailleurs",
  "dossierimmigration:participants": "Participants",
  "dossierimmigration:history": "Historique",
  "dossierimmigration:back": "Retour à la liste",
  "dossierimmigration:createdon": "Créée le",
  "dossierimmigration:warning":
    "Les renseignements quant au cheminement de votre dossier d'immigration peuvent vous être fournis dans l'unique mesure où FERME agit comme votre tierce partie pour la préparation de votre EIMT.",
  "dossierimmigration:details": "Items de détail",
  "dossierimmigration:details:createdon": "Créée le",
  "dossierimmigration:details:title": "Titre",
  "dossierimmigration:documents:processedon": "Traité le",
  "dossierimmigration:documents:noprocess": "Non traité",
  "dossierimmigration:tab:documents": "Documents",
  "messages:searchcontact:title": "Sélection des destinataires",
  "messages:users:role": "Rôle",
  "messages:users:title": "Nom",
  "messages:users:email": "Courriel",

  "messages:element": "Message",
  "messages:title": "Messagerie",
  "messages:inbox": "Boîte de réception",
  "messages:drafts": "Brouillons",
  "messages:sent": "Éléments envoyés",
  "messages:sent:tooltip": "Envoyé le {0}",
  "messages:newmessage": "Nouveau message",
  "messages:commandbar:aria":
    "Utiliser les flèches gauche et droite pour naviguer entre les commandes",
  "messages:sort:recent": "Récent",
  "messages:sort:older": "Ancien",
  "messages:sort:subject": "Sujet",
  "messages:sort:timestamp": "Date d'envoi",
  "messages:sort:recipient": "Destinataire",
  "messages:sort:from": "Destinataire",
  "messages:sort:type": "Type de communication",
  "messages:delete:title": "Supprimer un message",
  "messages:delete:subtext": "Voulez-vous vraiment supprimer ce message?",
  "messages:send": "Envoyer",
  "messages:save": "Sauvegarder",
  "messages:discard": "Annuler",
  "messages:senderror":
    "Une erreur est survenue lors de l'envoi de votre message.",
  "messages:savedrafterror":
    "Une erreur est survenue lors de la sauvegarde du brouillon.",
  "messages:savedraft:pleasewait":
    "Quelques instants... Nous sauvegardons votre brouillon.",
  "messages:send:pleasewait":
    "Quelques instants... Nous envoyons votre message.",
  "messages:subject": "Sujet",
  "messages:attach": "Joindre",
  "messages:designations": "Destinataires désignés",
  "messages:designations:dispatcher": "Répartiteur",
  "messages:recipients": "Destinataires",
  "messages:recipients:extra": "Destinataires additionnels",
  "messages:recipients:error": "Veuillez spécifier au moins un destinataire",
  "messages:subject:error": "Le titre du message est requis.",
  "messages:communicationtype": "Type de communication",
  "messages:message:error": "Veuillez entrer un message.",
  "global:searching": "Recherche en cours...",
  "transports:offer:title": "Nous chargons l'offre de voyage... Un instant...",
  "messages:assisted:newdeparture:passport": "Passeport",
  "messages:assisted:newdeparture:passport:invalid": "Passeport non valide",
  "messages:assisted:newdeparture:passport:invalid:instructions":
    "Ce travailleur n'a pas de passeport valide. Veuillez communiquer directement avec votre conseiller.",
  "messages:assisted:newdeparture:passport:invalid:instructions:arrival":
    "Vous pouvez tout de même continuer votre réservation pour une arrivée.",
  "messages:assisted:newdeparture:passport:invalid:instructions:departure":
    "Nous devons d'abord résoudre cette situation de passeport invalide afin de continuer avec une réservation pour ce participant.",
  "messages:assisted:participants": "Participants touchés par cette demande",
  "messages:assisted:participants:emptymessage":
    "Veuillez d'abord sélectionner une demande afin d'y afficher la liste des participants touchés.",
  "messages:assisted:participants:transfert:noparticipants":
    "La demande sélectionnée ne contient aucun participants assujettis à un transfert selon nos dossiers.",
  "messages:assisted:participants:depart:noparticipants":
    "La demande sélectionnée ne contient aucun participants assujettis à un départ selon nos dossiers.",
  "messages:assisted:participants:arrivee:noparticipants":
    "La demande sélectionnée ne contient aucun participants assujettis à une arrivée selon nos dossiers.",
  "messages:assisted:participants:transfer:noparticipants":
    "La demande sélectionnée ne contient aucun participants assujettis à un départ selon nos dossiers.",
  "messages:assisted:authorization:label": "Votre nom au complet",
  "messages:assisted:authorization:instructions":
    "Afin de confirmer que vous avez lu le texte ci-haut et que vous y donné votre autorisation, veuillez entrer votre nom complet.",
  "messages:assisted:authorization:title": "Autorisation requise",
  "messages:assisted:authorization:error:required":
    "Veuillez entrer votre nom au complet. Nous espérions {0}.",
  "messages:assisted:authorization:error:invalid":
    "Cette valeur ne corresponds pas à votre nom au complet. Nous espérions {0}.",
  "messages:assisted:authorization":
    "Je reconnais avoir pris connaissance de ce message et autorise FERME/Voyage à effectuer l'achat des meilleurs billets d'avion (prix et disponibilité des vols) sans qu'il soit nécessaire de me téléphoner avant pour obtenir à nouveau mon consentement. Je comprends également que les billets d'avion achetés au prix du marché n'offrent aucune possibilité de remboursement, d'échange ni de changement sans frais.",
  "messages:assisted:newdeparture:title": "Demande de départ",
  "messages:assisted:newdeparture:code": "Code",
  "messages:assisted:changedeparture:title": "Demande de départ",
  "messages:assisted:newdeparture:requestdate:error":
    "La date de la demande est requise",
  "messages:assisted:newdeparture:recall:error":
    "Le statut de rappel est requis",
  "messages:assisted:newdeparture:reason:error": "Raison requise",
  "messages:assisted:newdeparture:requestnumber:error":
    "Le numéro de la demande est requis",
  "messages:assisted:newarrival:maxseat":
    "Le trajet sélectionnées ne comporte pas assez de sièges disponible pour satisfaire votre sélection.",
  "messages:assisted:inspection:title": "Demande d'inspection",
  "messages:assisted:inspection:unavailable":
    "Les détails de votre demande d'inspection se retrouve dans votre message original",
  "messages:assisted:inspection:confirmation":
    "Avant la visite de l'inspecteur, ASSUREZ-VOUS d'avoir à jour les éléments suivants",

  "messages:assisted:inspection:confirmation:reminder":
    "**Le bâtiment doit être propre, impeccable et sécuritaire**",
  "messages:assisted:inspection:confirmation:footer":
    "Travaillons ensemble pour le mieux-être de tous !!!",

  "messages:assisted:inspection:confirmation:item1":
    "Un test d'eau valide de moins de 1 an (non requis si l'eau potable est fourni par un réseau d'aqueduc)",
  "messages:assisted:inspection:confirmation:item2":
    "Changez les piles des détecteurs de feu et vous assurez qu'ils soient tous fonctionnels et datant de moins de 10 ans",
  "messages:assisted:inspection:confirmation:item3":
    "Des extincteurs conformes de type ABC, de moins de 6 ans et en bonne condition, vissés près des armoires de cuisine",
  "messages:assisted:inspection:confirmation:item4":
    "Des équipements en bon nombres, propre et fonctionnels",
  "messages:assisted:inspection:confirmation:item5":
    "Des couvres-matelas (draps-contour acceptés) sur TOUS les matelas, ainsi que des oreillers convenables",

  "messages:assisted:newarrival:title": "Demande d'arrivée",
  "messages:assisted:newdeparture:participants:required":
    "Veuillez sélectionner au moins un participant pour votre demande de départ",
  "messages:assisted:enterprisechange:title": "Changement d'employeur",
  "messages:assisted:newdeparture:warning:title": "Un petit rappel",
  "messages:assisted:newdeparture:warning:content":
    "Si vous avez plusieurs participants de pays différents à faire voyager à une même date, assurez-vous de compléter vos demandes de départ au même moment.",
  "messages:assisted:newarrival:warning:content":
    "Si vous avez plusieurs participants de pays différents à faire voyager à une même date, assurez-vous de compléter vos demandes d'arrivée au même moment.",
  "messages:assisted:participanttransfert:requestnumber":
    "Numéro de la demande",
  "messages:assisted:participanttransfert:requestnumber:error":
    "Veuillez sélectionner une demande.",

  "messages:assisted:participanttransfert:warning:norequest:title":
    "Attention!",

  "messages:assisted:participanttransfert:norequest":
    "Aucune demande active avec changement d'employeur n'est disponible",
  "messages:assisted:participanttransfert:warning:title": "Un petit rappel",
  "messages:assisted:participanttransfert:warning:content":
    "N'indiquez ici qu'un seul nom d'employeur chez qui les participants sélectionnés seront transférés. Vous devez compléter une confirmation de changement d'employeur pour chaque employeur différent.",
  "messages:assisted:participanttransfert:participantcount":
    "Nombre de participant",
  "messages:assisted:participanttransfert:requestdate":
    "Date du changement d'employeur (transfert)",
  "messages:assisted:participanttransfert:requestdate:error":
    "La date de la requête est requise",
  "messages:assisted:participanttransfert:transfertenterprise":
    "Transféré(s) chez l'employeur",
  "messages:assisted:participanttransfert:transfertenterprise:error":
    "Le nom de l'employeur est requis.",

  "messages:assisted:participanttransfert:summary:title":
    "Sommaire de la demande de changement d'employeur",
  "messages:recipients:suggestedcontacts:1": "Personnes suggérés",
  "messages:recipients:suggestedcontacts:2": "Contacts suggérés",
  "messages:recipients:suggestedcontacts:noresults": "Aucun contact trouvé",
  "messages:recipients:suggestedcontacts:loading": "Chargement",

  "messages:commands:reply": "Répondre",
  "messages:commands:replyall": "Répondre à tous",
  "messages:commands:forward": "Transférer",
  "messages:gotoinbox": "Boîte de réception",
  "messages:type:standard": "Communication",
  "messages:type:general": "Communication générale",
  "messages:type:standard:desc": "Envoyer une communication standard",
  "messages:type:newdeparture": "Demande de départ",
  "messages:type:newdeparture:desc":
    "Envoyer une nouvelle demande de transport pour un départ",
  "messages:type:changedeparture":
    "Changement à une demande de départ antérieure",
  "messages:type:changedeparture:desc":
    "Envoyer un changement sur une demande de départ antérieure",
  "messages:type:transfert": "Confirmation de transfert",
  "messages:type:transfert:desc":
    "Envoyer une confirmation sur un changement d'employeur",
  "messages:type:newarrival": "Demande d'arrivée",
  "messages:type:newarrival:desc":
    "Envoyer une nouvelle demande de transport pour une arrivée",
  "messages:type:newarrival:transport": "Demande de transport par autobus",
  "messages:type:newarrival:transport:desc":
    "Envoyer une nouvelle demande de transport pour un arrivée",
  "messages:type:inspection": "Demande d'inspection des logements",
  "messages:type:inspection:desc":
    "Envoyer une nouvelle demande d'inspection pour vos batîments.",

  "messages:assisted:newdeparture:charter:label": "Vols nolisés",
  "messages:assisted:newdeparture:charter:selectother":
    "Sélectionner un autre vol",
  "messages:assisted:newdeparture:charter:places":
    "Seulement {0} places de disponible",
  "messages:assisted:newdeparture:charter:select": "Choisir",
  "messages:assisted:newdeparture:charter:warning:title":
    "Politique sur les vols nolisés",
  "messages:assisted:newdeparture:charter:warning:content":
    "La préselection de vol nolisé ne constitue pas une garantie absolue quant à l'accès au vol nolisé. Il se peut que des contraintes de force majeure forcent FERME/Voyages à rediriger vos travailleurs sur d'autres vols. La confirmation d'arrivée ou de départ confirmera le vol final sur lequel vos travailleurs voyageront.",
  "messages:assisted:newdeparture:charter:noflight":
    "Aucun vol nolisé n'est prévu selon les critères de votre demande.",
  "messages:assisted:newdeparture:charter:selection":
    "Sélection d'un vol nolisé",
  "messages:assisted:preauth:1":
    "En octroyant l'autorisation d'acheter les billets d'avion au prix du marché, vous permettez à FERME/Voyages de faire la recherche des billets les moins chers pour la date demandée du transport.",
  "messages:assisted:preauth:2":
    "Cela signifie que les conseillers de FERME/Voyages n'auront pas à vous téléphoner avant de procéder à la réservation des billets d'avion au prix du marché, et ce, dans la seule mesure où le prix est inférieur à 500.00 $ par billet (le prix de l'entente négociée se situant autour de 600.00 $).",

  "messages:assisted:preauth:3":
    "Veuillez sélectionner cette option si vous acceptez d'accorder cette autorisation",

  "messages:assisted:newarrival:warning:title": "Un petit rappel",
  "messages:assisted:newarrival:warning:1":
    "Les frais relatifs à ce service vous seront facturés par FERME/Voyages.",
  "messages:assisted:newarrival:warning:2":
    "Un coût additionnel est à prévoir si vos travailleurs arrivent directement à votre entreprise.",

  "messages:assisted:newdeparture:requestnumber": "Numéro de demande",
  "messages:assisted:newdeparture:preauth:label": "Préautorisation d'achat",
  "messages:assisted:newdeparture:origincity": "Ville d'origine du départ",
  "messages:assisted:newdeparture:origin": "Départ",
  "messages:assisted:newdeparture:flight": "Numéro de vol",
  "messages:assisted:newdeparture:origincity:error":
    "Veuillez sélectionner votre ville de départ",
  "messages:assisted:newdeparture:destination": "Destination",
  "messages:assisted:newdeparture:destinationcity": "Ville intérieure",
  "messages:assisted:newdeparture:reason": "Raison du voyage",
  "messages:assisted:newdeparture:reasonrecall": "Raison du non rappel",
  "messages:assisted:newdeparture:recall": "Statut du rappel",
  "messages:assisted:newdeparture:participant": "Nom du participant",
  "messages:assisted:newdeparture:requestdate": "Date demandée",
  "messages:assisted:newdeparture:select": "Sélectionner...",
  "messages:assisted:newdeparture:leavereason": "Raison du départ",
  "messages:assisted:newdeparture:returndate":
    "Date de retour (si connue et applicable)",
  "messages:assisted:newdeparture:returndate:short": "Date de retour",
  "messages:assisted:newdeparture:summary:title":
    "Sommaire de la demande de départ",
  "messages:assisted:newarrival:summary:title":
    "Sommaire de la demande d'arrivée",

  "messages:assisted:newdeparture:unknown": "Inconnu",
  "messages:assisted:newdeparture:none": "Aucune",
  "messages:assisted:newdeparture:other": "Autre",
  "messages:assisted:newdeparture:estimatedarrivaldate":
    "Date d'arrivée prévue",
  "messages:assisted:newdeparture:estimateddeparturedate":
    "Date de départ prévu",
  "messages:assisted:newdeparture:completeaddress": "Adresse complète",
  "messages:assisted:newdeparture:estimateddate": "Date de départ prévu",
  "messages:assisted:newdeparture:landrequested":
    "La présente demande de départ doit inclure du transport terrestre.",
  "messages:assisted:newdeparture:landrequested:toggle":
    "Inclure transport terrestre",
  "messages:assisted:newdeparture:preauthrequested":
    "Vous acceptez la pré-autorisation d'achat pour la présente demande de départ.",
  "messages:assisted:newarrival:preauthrequested":
    "Vous acceptez la pré-autorisation d'achat pour la présente demande d'arrivée.",
  "messages:assisted:newarrival:option:custom":
    "Transport à une adresse spécifique",
  "messages:assisted:newarrival:option:city": "Ville d'arrivée de base",
  "messages:assisted:newdeparture:unspecified": "Non spécifié",
  "messages:assisted:newarrival:requestnumber": "Numéro de la demande",
  "messages:assisted:newarrival:select": "Sélectionner...",
  "messages:assisted:newarrival:requestdate": "Date de la requête",
  "messages:assisted:newarrival:enterprise": "Employeur",
  "messages:assisted:newarrival:city": "Ville de l'arrivée",
  "messages:assisted:newarrival:participant": "Nom du participant",
  "messages:assisted:newarrival:requestdate:error":
    "Une date pour la demande est requise",
  "messages:assisted:newarrival:requestnumber:error":
    "Veuillez sélectionner une demande",
  "messages:assisted:newarrival:city:error": "Veuillez sélectionner une ville",
  "messages:assisted:newarrival:custom:error":
    "Veuillez entrer une adresse pour l'arrivée",
  "messages:assisted:inspection:address": "Adresse de l'inspection",
  "messages:assisted:inspection:enterprise": "Employeur",
  "messages:assisted:inspection:branch": "Établissement",
  "messages:assisted:inspection:street": "Rue",
  "messages:assisted:inspection:city": "Ville",
  "messages:assisted:inspection:postalcode": "Code postal",
  "messages:assisted:inspection:state": "Province",
  "messages:assisted:inspection:contact": "Contact",
  "messages:assisted:inspection:comments": "Commentaires",
  "messages:assisted:inspection:contacts": "Contacts de l'employeur",
  "messages:assisted:inspection:contactname": "Nom complet du contact",
  "messages:assisted:inspection:ceaCenterEmail": "Courriel Centre CEA",
  "messages:assisted:inspection:phone": "Numéro de téléphone",
  "messages:assisted:inspection:email": "Courriel",
  "messages:assisted:inspection:details": "Détails de l'inspection",
  "messages:assisted:inspection:building": "Nombre de batiments",
  "messages:assisted:inspection:workers": "Nombre de travailleurs F.E.R.M.E",
  "messages:assisted:inspection:workers:extern":
    "Nombre de travailleurs autre que F.E.R.M.E",
  "messages:assisted:inspection:building:error":
    "Le nombre de bâtiments doit être supérieur à zéro",
  "messages:assisted:inspection:workers:error":
    "Le nombre de travailleurs doit être supérieur à zéro.",
  "messages:newmessage:discard:title": "Nouveau message en édition",
  "messages:newmessage:discard:message":
    "Êtes-vous sûr de vouloir annuler l'édition de ce nouveau message?",
  "messages:assisted:inspection:dates": "Dates de référence",
  "messages:assisted:inspection:estimateddeposit":
    "Date de dépôt prévu de l'EIMT",
  "messages:assisted:inspection:lastinspection":
    "Date du dernier rapport d'inspection",
  "messages:assisted:inspection:select": "Sélectionner...",
  "messages:assisted:inspection:contactname:error":
    "Le nom du contact est requis.",
  "messages:assisted:inspection:ceaCenterEmail:error": 
  "Le CEA concerné est requis.",
  "messages:assisted:inspection:phone:error":
    "Le numéro de téléphone est requis.",
  "messages:assisted:inspection:email:error": "Le courriel est requis.",
  "messages:assisted:inspection:estimateddeposit:error":
    "La date estimée de dépot de l'EIMT est requise.",
  "messages:assisted:inspection:lastinspection:error":
    "La date du dernier rapport d'inspection est requise.",
  "messages:assisted:inspection:confirmation:title": "Confirmation",

  "messages:assisted:inspection:confirmation:header":
    "Confirmation de l'adresse de l'inspection",
  "messages:assisted:inspection:confirmation:description":
    "Vous avez indiqué l'adresse suivante pour les batîments à inspecter.",
  "messages:assisted:inspection:confirmation:description:plural":
    "Vous avez indiqué les adresses suivantes pour les batîments à inspecter.",
  "messages:assisted:inspection:confirmation:instructions":
    "Veuillez attester que cette adresse est correct et exacte, sinon annuler cette confirmation et retournez dans la demande et corrigez les informations.",
  "messages:assisted:inspection:checkbox":
    "J'atteste que cette adresse est correcte et exacte.",
  "messages:assisted:inspection:askifishousingowner":"Êtes vous le propriétaire du logement à inspecter ?",
  "messages:assisted:inspection:askownerfullname":"Veuillez écrire le nom complet du propriétaire",
  "messages:assisted:inspection:askbedcount":"Quel est le nombre de lit à cette adresse ?",
  "messages:assisted:inspection:askifrenttootheremployer":"Louez-vous ce logement à d'autres employeurs ?",
  "messages:assisted:inspection:askiftakeworkerextraferme":"Prenez-vous des travailleurs étrangers ailleurs que chez F.E.R.M.E ?",
  "messages:assisted:inspection:sendreporttocenter":"Souhaitez-vous que votre rapport d'inspection soit aussi acheminé au Centre d'emploi agricole (CEA) qui traitera votre demande d'EIMT ?",
  "messages:assisted:inspection:message:placeholder":"Veuillez écrire votre message ici",
  "messages:assisted:transfert:code": "Code",
  "messages:assisted:transfert:date": "Date prévue du départ",
  "messages:reply:format:subjet": "Sujet",
  "messages:reply:format:from": "De",
  "messages:reply:format:to": "À",
  "messages:reply:format:created": "Créer le",

  "requests:noevent": "Aucun départ ou arrivée",
  "requests:nextdeparture": "Prochain départ",
  "requests:nextarrival": "Prochaine arrivée",
  "requests:nextevent": "Prochain événement",
  "requests:requestnumber": "Numéro de demande",
  "requests:createdon": "Créée le",
  "requests:creationdate": "Date de création de la version",
  "requests:country": "Pays",
  "requests:agent": "Agent",
  "requests:status": "Statut",
  "requests:sector": "Secteur",
  "requests:offptas": "Hors PTAS",
  "requests:thirdParty": "Tierce Partie",
  "requests:participantcount": "Nombre de participants",
  "requests:version": "# de version",
  "requests:status:closed": "Fermée",
  "requests:status:confirmed": "Confirmée",
  "requests:status:draft": "Brouillon",
  "requests:participants": "participants",
  "requests:participantslabel": "Participants",
  "requests:request": "Demande",
  "requests:info": "Infos",
  "requests:year": "Année",
  "requests:version_": "Version",
  "requests:title": "Titre de la version",
  "requests:periods": "Périodes",
  "requests:backtolist": "Retour à la liste",
  "requests:fromto": "Période du {0} au {1}",
  "requests:fromto:eimt": "Période du {0} au {1} - EIMT: {2}",
  "request:EIMT:number": "Numéro EIMT",
  "requests:branch": "Établissement",
  "requests:postes": "postes",
  "requests:poste": "poste",
  "requests:departure:status": "Statut de départ",
  "requests:departure:date": "Date de départ",
  "requests:arrival:status": "Statut d'arrivée",
  "requests:arrival:date": "Date d'arrivée",
  "requests:postes:title": "Postes",
  "requests:poste:title": "Poste",
  "requests:period": "Période",
  "requests:period:startdate": "Date de début",
  "requests:period:enddate": "Date de fin",
  "requests:period:eimt": "EIMT",
  "requests:period:count": "Nombre de postes",
  "requests:period:eimt:delivery": "Date d'émission EIMT",
  "requests:period:eimt:expiration": "Date d'expiration EIMT",
  "requests:backrequest": "Retour à la demande",
  "requests:backtoriginal": "Retour à la version originale",
  "requests:contacts": "Contacts",
  "requests:participant:lastname": "Nom",
  "requests:participant:firstname": "Prénom",
  "requests:participant:participantnumber": "Numéro de participant",
  "requests:participant:participanttype": "Type de participant",
  "requests:participant:visanumber": "Numéro de visa",
  "requests:participant:caqnumber": "Numéro CAQ",
  "requests:participants:eimt": "Numéro EIMT",
  "requests:participants:eimtexpiration": "Expiration EIMT",
  "requests:participants:hourlyrate": "Taux horraire",
  "requests:participants:participantnumber": "Participants",
  "requests:periods:arrivalfrom": "Arrivées du ",
  "requests:periods:departurefrom": "Départs du ",
  "requests:periods:arrival": "Arrivée",
  "requests:periods:departure": "Départ",
  "requests:periods:completed": "Complétée",

  "requests:periods:grouping:firstname": "Participant",
  "requests:periods:grouping:participanttype": "Type de participant",
  "requests:periods:grouping:title": "Poste",
  "requests:periods:grouping:arrivalstatus": "Statut d'arrivée",
  "requests:periods:grouping:departurestatus": "Statut de départ",
  "requests:periods:grouping:arrivaldate": "Date d'arrivée",
  "requests:periods:grouping:departuredate": "Date de départ",
  "requests:periods:grouping:participantnumber": "Code",
  "requests:periods:grouping:readytotravel": "Travailleurs prêts à voyager",
  "requests:periods:grouping:withhistory": "Avec Historique",
  "requests:periods:grouping:withouthistory": "Sans Historique",
  "requests:history:header": "Versions",
  "requests:history:name": "Titre de la version",
  "requests:history:createdby": "Créer par",
  "requests:history:createdon": "Créée le",
  "requests:history:goto": "Consultation",
  "requests:history:goto:label": "Consulter cette version",
  "requests:participants:item:readytotravel:title":
    "Travailleurs prêts à voyager",
  "requests:participants:item:readytotravel:description":
    "Ce participant est maintenant prêt à voyager",
  "requests:periods:grouping:period": "Par période",
  "requests:periods:grouping:next": "Par prochain événement",

  "requests:periods:participants:title": "Poste",
  "requests:periods:participants:name": "Nom du participant",
  "requests:periods:participants:number": "Code",
  "requests:periods:participants:type": "Type de participant",

  "requests:participants:item:name": "Nom du participant",
  "requests:participants:item:number": "Code",
  "requests:participants:item:visa": "Numéro du visa",
  "requests:participants:item:visa:reception": "Date de réception du visa",
  "requests:participants:item:visa:expiration": "Date d'expiration du visa",
  "requests:participants:item:type": "Type de participant",
  "requests:participants:item:birthdate": "Date de naissance",
  "requests:participants:item:gender": "Genre",
  "requests:participants:item:gender:m": "Masculin",
  "requests:participants:item:gender:f": "Féminin",
  "requests:participants:item:arrival:estimated": "Date d'arrivée anticipée",
  "requests:participants:item:arrival:real": "Date d'arrivée réel",
  "requests:participants:item:arrival:type": "Type de transport à l'arrivée",
  "requests:participants:item:caq": "Certificat de sélection du Québec",
  "requests:participants:item:status": "Statut du participant",
  "requests:participants:item:departure:estimated": "Date de départ anticipée",
  "requests:participants:item:departure:real": "Date de départ réel",
  "requests:participants:item:departure:type": "Type de transport au départ",
  "requests:participants:item:country": "Pays",
  "requests:participants:item:position": "Poste",
  "requests:participants:item:end:type": "Type de fin de participation",
  "requests:participants:item:end:recall:status":
    "Statut de rappel du participant",
  "requests:participants:item:end:recall:reason": "Raison du non rappel",
  "requests:participants:item:end:notes": "Notes de fin de participation",
  "requests:participants:item:notes:empty":
    "Aucun notes présentes pour ce participants.",
  "requests:participants:item:back": "Retour à la liste",

  "requests:participants:item:pathway:medical":
    "Date de requête d'examen médical",
  "requests:participants:item:pathway:eimt": "Date de réception de l'EIMT",
  "requests:participants:item:pathway:signature":
    "Date de signature des documents",
  "requests:participants:item:pathway:annexe":
    "Date de réception de l'annexe B",
  "requests:participants:item:pathway:deposit":
    "Date de dépôt de la demande de visa",
  "requests:participants:item:pathway:delivery": "Date d'obtention du visa",
  "requests:participants:item:pathway:status": "Statut",
  "requests:positions:title": "Titre du poste",
  "requests:positions:cnp": "Code CNP",
  "requests:positions:skills": "Exigences du poste",
  "requests:positions:otherskills": "Exigences du poste",
  "requests:positions:noskills": "Ce poste ne contient aucune exigence",
  "requests:positions:desc": "Exposé des fonctions",
  "requests:positions:rate": "Taux horaire",
  "requests:positions:details:ticket": "Numéro de billet",
  "requests:positions:details:type": "Type de déplacement",
  "requests:positions:details:type:flight": "Aérien",
  "requests:positions:details:type:employer": "Changement d'employeur",
  "requests:positions:details:type:other": "Autre",
  "requests:positions:details:type:contractrenewd": "Contract renouvelé",
  "requests:positions:details:type:workerescaped": "Travailleur en fuite",
  "requests:periods:all": "Toutes les périodes",
  "requests:periods:perperiod": "Par période",
  "requests:periods:next": "Per prochain événement",

  "requests:print": "Imprimer la fiche demande",
  "requests:reports:type:report": "Fiche de la demande",
  "requests:reports:type:arrival": "Confirmation des arrivées",
  "requests:reports:type:departure": "Confirmation des départs",
  "requests:reports:types": "Type de rapports sur les demandes",
  "requests:reports:type:date:all": "Toutes les dates",
  "requests:reports:type:departure:date": "Date des départs",
  "requests:reports:type:arrival:date": "Date des arrivées",
  "requests:printrequest": "Imprimer",
  "requests:positions:all": "Tous les postes",
  "requests:AERIEN": "Aérien",
  "requests:TRANSFERT": "Changement d'employeur",
  "requests:AUTRE": "Autre",
  "requests:NONE": "Aucun",
  "requests:documents": "Documents",
  "requests:pathway": "Cheminement",
  "requests:endparticipation": "Fin de participation",
  "requests:notes": "Notes",
  "transports:element:type": "Transport {0}",
  "transports:requests:summary:header": "Tableau de bord",
  "transports:requests:arrivals:header": "Arrivées",
  "transports:requests:departures:header": "Départs",
  "transports:requests:billetterie:header": "Billetterie",
  "transports:arrivals:element": "Arrivées",
  "transports:departures:element": "Départs",
  "transports:requests:requestnumber": "Numéro de demande",
  "transports:requests:type": "Type de déplacement",
  "transports:requests:legend": "Légende des déplacements",
  "transports:requests:typeicon": "Statut du déplacement",
  "transports:requests:date": "Date",
  "transports:offers:waranty": "La disponibilité n'est pas garantie",
  "transports:offers:itenarytext:singular": "itinéraire disponible",
  "transports:offers:title":
    "Des vols juste à temps, voici nos suggestions en ce moment",
  "transports:offers:itenarytext:plural": "itinéraires differents disponibles",
  "transports:offers:arrivals:card:instructions":
    "Des arrivées sont prévu pour le ",
  "transports:offers:departures:card:instructions":
    "Des départs sont prévu pour le ",
  "transports:requests:passengers": "Nombre de passagers",
  "transports:requests:passengers:singular": "passenger",
  "transports:requests:passengers:plural": "passengers",
  "transports:requests:legend:regular": "Vol régulier",
  "transports:requests:legend:charter": "Vol nolisé",
  "transports:requests:legend:confirmed": "Vol régulier",
  "transports:requests:legend:requested": "Demande de vol en cours",
  "transports:requests:legend:currenttransfert": "Transfert non completé",
  "transports:requests:legend:transfert": "Transfert completé",
  "transports:requests:legend:othercurrent": "Autre",
  "transports:requests:legend:workerescaped": "Travailleur en fuite",
  "transports:requests:legend:contractrenewd": "Contract renouvelé",
  "transports:requests:legend:other": "Autre",
  "transports:requests:legend:leaving": "Vol non encore déterminé",
  "transports:requests:legend:leaving:desc": "",
  "transports:requests:legend:regular:desc": "Prix du marché",
  "transports:requests:legend:charter:desc": "",
  "transports:requests:legend:confirmed:desc": "Prix entente",
  "transports:requests:legend:requested:desc": "",
  "transports:requests:legend:currenttransfert:desc": "",
  "transports:requests:legend:transfert:desc": "",
  "transports:requests:legend:othercurrent:desc": "",
  "transports:requests:legend:other:desc": "Demande de trajet",
  "transports:flightcriteria:instructions:arrivals": "arrivées",
  "transports:flightcriteria:instructions:departures": "départs",
  "transports:flightcriteria:instructions:1":
    "Sélectionnez les villes d'origine et de ",
  "transports:flightcriteria:instructions:2":
    " destination ainsi que la date souhaitée du vol.",
  "transports:flightcriteria:from:error":
    "Veuillez enter un aéroport de départ",
  "transports:flightcriteria:to:error":
    "Veuillez enter un aéroport de destination",
  "transports:flightcriteria:travelwarning:title":
    "Avertissements officiels aux voyageurs à l'étranger",
  "transports:flightcriteria:travelwarning:desc":
    "Le gouvernement canadien a émis des avertissements officiels aux voyageurs à l'étranger a l'égard du coronavirus (COVID-19).",
  "transports:flightcriteria:travelwarning:url":
    "https://voyage.gc.ca/voyager/avertissements",
  "transports:flightcriteria:generalwarning:name": "",
  "transports:flightcriteria:generalwarning:description":
    "Cette plateforme en est une de réservation des vols nolisé et des blocs de siège sur des vols opérés par des transporteurs au bénéfice de FERME/Voyages.",
  "transports:flightcriteria:generalwarning:subtext":
    "Permis d'agence de voyages délivré par l'OPC # 702576",

  "transports:flights:conditions:arrival:standard:title":
    "Conditions du billet et franchise de bagages",

  "transports:flights:conditions:arrival:standard:1":
    "Le billet est remboursable en cas de NO SHOW en raison d'un test covid positif",
  "transports:flights:conditions:arrival:standard:2":
    "Bagages admis : 1 valises de 15 kg MAXIMUM + 1 bagage à main de 5 kg MAXIMUM inclus dans le prix",
  "transports:flights:conditions:arrival:standard:3":
    "Aucuns bagages supplémentaires acceptés par la compagnie aérienne. Veuillez SVP respecter les dimensions et le poids des valises, sinon cela posera de réels problèmes à l'aéroport et le vol sera alors retardé.",
  "transports:flights:conditions:arrival:standard:4":
    "Attention : AUCUN bagage surdimensionné n'est accepté par la compagnie aérienne, c'est-à-dire les vélos, télévisions et instruments musicaux ne seront pas admis à bord. Veuillez communiquer l'information à vos travailleurs, c'est votre responsabilité en tant qu'employeur.",

  "transports:flights:conditions:arrival:am:1":
    "Le billet est au prix du contrat est non remboursable mais avec possibilité d'effectuer un changement gratuit (date du vol ou nom de passager)",

  "transports:flights:conditions:arrival:am:2":
    "Le 2ième changement est au prix de 150$ CAD",
  "transports:flights:conditions:arrival:am:3":
    "Bagages admis : 1 valise de 23 kg MAXIMUM + 1 bagage à main de 10 kg MAXIMUM inclus dans le prix.",
  "transports:flights:conditions:arrival:am:4":
    "Attention : 2eme bagage de 23 kg MAXIMUM au prix de 50$ CAD, à payer directement au comptoir d'enregistrement d'Aeromexico en argent comptant ou par carte de crédit.",

  "transports:flights:conditions:departure:standard:title":
    "Conditions du billet et franchise de bagages",

  "transports:flights:conditions:departure:standard:1":
    "Le billet est remboursable en cas de NO SHOW en raison d'un test covid positif",
  "transports:flights:conditions:departure:standard:2":
    "Bagages : 2 valises de 23 kg MAXIMUM + 1 bagage à main de 5 kg MAXIMUM inclus dans le prix.",
  "transports:flights:conditions:departure:standard:3":
    "Aucuns bagages supplémentaires acceptés par la compagnie aérienne. Veuillez SVP respecter les dimensions et le poids des valises, sinon cela posera de réels problèmes à l'aéroport et le vol sera alors retardé.",
  "transports:flights:conditions:departure:standard:4":
    "Attention : AUCUN bagage surdimensionné n'est accepté par la compagnie aérienne, c'est-à-dire les vélos, télévisions et instruments musicaux ne seront pas admis à bord. Veuillez communiquer l'information à vos travailleurs, c'est votre responsabilité en tant qu'employeur.",

  "transports:flights:conditions:departure:am:1":
    "Le billet est au prix du contrat est non remboursable mais avec la possibilité d'effectuer un changement gratuit (date du vol ou nom de passager)",

  "transports:flights:conditions:departure:am:2":
    "Le 2ième changement est au prix de 200$ CAD",
  "transports:flights:conditions:departure:am:3":
    "Bagages admis : 1 valise de 23 kg MAXIMUM + 1 bagage à main de 10 kg MAXIMUM inclus dans le prix.",
  "transports:flights:conditions:departure:am:4":
    "Attention : 2eme bagage de 23 kg MAXIMUM au prix de 50$ CAD, à payer directement au comptoir d'enregistrement d'Aeromexico en argent comptant ou par carte de crédit.",

  "transports:flights:confirmation:message":
    "En confirmant ma réservation, je reconnais accepter les conditions afférentes à l'émission des billets d'avion, les conditions d'annulation et de remboursement, telles qu'indiquées en cours de processus de réservation.",
  "transports:flights:confirmation:buttons:submit": "Je confirme",
  "transports:flights:confirmation:buttons:cancel": "Annuler",

  "transports:flightcriteria:passengers": "Passagers",
  "transports:flightcriteria:passengersnumber": "Nombre de passagers",
  "transports:flightcriteria:flexible": "Date flexible",
  "transports:flightcriteria:market": "Vols commerciaux",
  "transports:flightcriteria:charter": "Vols nolisés",
  "transports:flightcriteria:passengers:error":
    "Veuillez saisir le nombre de passagers",
  "transports:flights:download:pdf":
    "Télécharger le rapport de réservation en format PDF",
  "transports:flights:download:pdf:button": "Télécharger",
  "transports:flights:download:excel":
    "Télécharger la liste des participants en format Excel",
  "transports:flights:download:excel:button": "Télécharger",
  "transports:flights:departurereason": "Raison de départ",
  "transports:flights:departurereason:select": "Choisir une raison",
  "transports:flights:traveldate": "Date du voyage",
  "transports:flights:billingdate": "Date de facturation",
  "transports:flights:paymentdate": "Date de paiement",
  "transports:flightcriteria:date:error":
    "Veuillez choisir une date de début et de fin pour la recherche",
  "transports:flights:origindest:label": "Origine-Destination",
  "transports:flights:passengerscount:label": "passager",
  "transports:flightbooking:taxes": "Taxes",
  "transports:flightbooking:taxes:included": "Incluses",
  "transports:flightbooking:adult": "Adulte",
  "transports:flightbooking:adults": "Adultes",
  "transports:flightbooking:paybutton": "Payer",
  "transports:flightbooking:reservebutton": "Confirmer votre réservation",
  "transports:flights:participants:selection:error":
    "Veuillez sélectionner {0} participants.",
  "transports:flights:bestprice:before:days": "jours avant",
  "transports:flights:bestprice:after:days": "jours après",
  "transports:flights:bestprice:before":
    "Meuilleur prix pour les 3 jours antérieures",
  "transports:flights:bestprice:after":
    "Meilleur prix pour les 3 prochains jours",
  "transports:flights:totalpricewarning:singular":
    "Le prix total inclus les taxes et les frais additionnels pour {0} adulte. ",
  "transports:flights:totalpricewarning:plural":
    "Le prix total inclus les taxes et les frais additionnels pour {0} adultes. ",
  "transports:flights:totalpricewarning:baggagelink":
    "Frais de baggages additionnels",
  "transports:flights:totalpricewarning:baggage:suffix":
    " et d'autres frais peuvent s'appliquer.",
  "transports:flights:currencywarning":
    "Nous vous rappelons que tous les prix sont affichés en dollars canadiens (CAD).",
  "transports:flights:requests": "Demandes",
  "transports:flights:participants:passport": "Numéro de passeport",
  "transports:flights:criteria:title": "Recherche de vols",
  "transports:flights:criteria:arrivals": "Arrivées",
  "transports:flights:criteria:departures": "Départs",
  "transports:flights:booking:search": "Recherche",
  "transports:flights:booking:summary": "Sommaire",
  "transports:flights:booking:checkout": "Sélection",
  "transports:flights:booking:confirmation": "Confirmation",

  "transports:summary:pricing": "Résumé des modalités",

  "transports:flights:tripsummary": "Sommaire du voyage",
  "transports:flights:participants:selection": "Passagers sélectionnés",
  "transports:flights:participants:count": "Nombre de participants",
  "transports:flights:tripsummary:back": "Retour aux résultats de recherche",

  "transports:flights:noresult": "Aucun résultat trouvé",
  "transports:flights:loading": "Chargement en cours",
  "transports:flights:searching": "Recherche de vols en cours...",

  "transports:flights:billingmethod": "Méthode de Facturation",
  "transports:flights:billingmethod:local": "Facture émise par: Ferme Voyage",
  "transports:flights:billingmethod:paying":
    "Veuillez patientez... Nous procédons avec votre paiement.",
  "transports:flights:airport:aria": "Sélection d'aéroport",
  "transports:flights:airport:origin": "Origine",
  "transports:flights:airport:destination": "Destination",
  "transports:flights:airport:origin:placeholder": "À partir de",
  "transports:flights:airport:destination:placeholder": "À destination de",
  "transports:flights:airportavailable": "Aéroports disponibles",
  "transports:flights:airport:layover": "halte",
  "transports:flights:airport:stops": "connections",
  "transports:flights:airport:nonstops": "Direct",
  "transports:flights:operatedby": "Opéré par",
  "transports:flights:summary": "Sommaire du voyage",
  "transports:flights:summary:participants": "Participants sur le voyage",
  "transports:flights:buynow": "Acheter maintenant",
  "transports:flights:booknow": "Réserver maintenant",
  "transports:flights:participants:code": "Code",
  "transports:flights:participants:estimatedarrival":
    "Date d'arrivée anticipée",
  "transports:flights:participants:estimateddeparture":
    "Date de départ anticipée",
  "transports:flights:request:instruction:departure":
    "Veuillez sélectionner la demande conserné par les départs.",
  "transports:flights:request:instruction:arrival":
    "Veuillez sélectionner la demande concernée par les arrivées.",

  "transports:flights:back:selection": "Retour à la sélection",
  "transports:flights:back:search": "Retour aux résultats de recherche",
  "transports:flights:back": "Retour",
  "transports:summary:passenger": "Passager",
  "transports:summary:code": "Code",
  "transports:summary:request": "Numéro de demande",
  "transports:summary:undefined": "Trajet aérien indéfini",

  "contacts:email": "Courriel",
  "contacts:communication": "Communication",
  "contacts:phone": "Téléphone",
  "contacts:cellphone": "Cellulaire",
  "contacts:roles": "Roles & options",

  "users:title": "Name",
  "users:email": "Email",
  "users:role": "Role",

  "error:500:title": "Un problème est survenue",
  "error:500:id": "Identifiant de l'événement",
  "error:500:message": "Message",
  "error:500:back": "Retour à la page d'accueil",
  "error:500:date": "Date",
  "error:500:message:default":
    "Désolé, une erreur grave est survenue. Nous regardons le problème en ce moment.",
  "error:download:document":
    "Désolé, ce document n'est pas dispobible pour l'instant.",

  "validator:mandatory:error":
    "Champ obligatoire, ne peut pas être laissé vide",
  "validator:number:error": "Autoriser uniquement les valeurs numériques",
  "validator:email:error": "Doit être une adresse courriel valide",
  "validator:decimal:error": "Autoriser uniquement les valeurs décimales",
  "validator:invalid:error": "Valeur invalide",
  "validator:only:allow": "Autoriser uniquement",
  "validator:validvalue": "Sélectionnez une valeur valide",
  "validator:validdate": "Doit être une date valide",
  "validator:length:between": "Doit avoir une longueur entre ",
  "validator:length:maxlength": "La longueur maximum pour ce champ est de ",
  "validator:characters": "caractères",
  "validator:compare:error": "La confirmation ne coressponds pas",

  "global:search": "Rechercher",
  "commandbar:arialabel":
    "Utiliser les flèches gauche et droite pour naviguer entre les commandes",
  "global:peoplepicker": "Sélecteur de personnes",
  "global:ascending": "ascendant",
  "global:descending": "descendant",
  "global:close": "Fermer",
  "global:open": "Ouvrir",
  "global:yes": "Oui",
  "global:no": "Non",
  "global:apply": "Appliquer",
  "global:active": "Actif",
  "global:inactive": "Inactif",
  "global:ok": "Ok",
  "global:cancel": "Annuler",
  "grid:filterby": "Filtrer par",
  "grid:filterby:placeholder": "Entrez le texte à filtrer...",
  "grid:sort": "Trier",
  "grid:filter": "Filtrer",
  "grid:selectall": "Sélectioner tous les éléments",
  "grid:selectnone": "Désélectionner tous les éléments",
  "grid:clearfilters": "Enlever tous les filtres",
  "grid:gridview": "Grille",
  "grid:list": "Liste",
  "grid:summary": "Sommaire",
  "grid:export": "Exporter",
  "grid:export:full": "Exporter tout",
  "grid:export:filtered": "Exporter éléments filtrés",
  "grid:export:annee": "Exporter selon l'année",

  "global:copyrights":
    "2014-2021 © Tous droits réservés &centerdot; FERME Québec",
  "global:justnow": "à ce moment",
  "global:noagent": "Aucun agent attritré",
  "global:days": "jours",
  "global:daysbefore": "jours avant",
  "global:day": "jour",
  "global:daybefore": "jour avant",
  "global:print": "Imprimer",
  "global:between": "entre le",
  "global:and": "et le",
  "global:of": "de",
  "global:emptyitemsmessage": "Aucun élément à afficher.",

  // Calendar Strings
  "calendars:months:january": "Janvier",
  "calendars:months:february": "Février",
  "calendars:months:march": "Mars",
  "calendars:months:april": "Avril",
  "calendars:months:may": "Mai",
  "calendars:months:june": "Juin",
  "calendars:months:july": "Juillet",
  "calendars:months:august": "Août",
  "calendars:months:september": "Septembre",
  "calendars:months:october": "Octobre",
  "calendars:months:november": "Novembre",
  "calendars:months:december": "Décembre",

  "calendars:months:january:short": "Jan",
  "calendars:months:february:short": "Fev",
  "calendars:months:march:short": "Mar",
  "calendars:months:april:short": "Avr",
  "calendars:months:may:short": "Mai",
  "calendars:months:june:short": "Jun",
  "calendars:months:july:short": "Jul",
  "calendars:months:august:short": "Aou",
  "calendars:months:september:short": "Sep",
  "calendars:months:october:short": "Oct",
  "calendars:months:november:short": "Nov",
  "calendars:months:december:short": "Dec",

  "calendars:days:sunday": "Dimanche",
  "calendars:days:monday": "Lundi",
  "calendars:days:tuesday": "Mardi",
  "calendars:days:wednesday": "Mercredi",
  "calendars:days:thursday": "Jeudi",
  "calendars:days:friday": "Vendredi",
  "calendars:days:saturday": "Samedi",

  "calendars:days:sunday:short": "D",
  "calendars:days:monday:short": "L",
  "calendars:days:tuesday:short": "M",
  "calendars:days:wednesday:short": "M",
  "calendars:days:thursday:short": "J",
  "calendars:days:friday:short": "V",
  "calendars:days:saturday:short": "S",
  "calendars:today": "Aujourd'hui",
  "calendars:weeks": "Semaine numéro {0}",
  "calendars:previousmonth": "Mois précédent",
  "calendars:previousyear": "Année précédente",
  "calendars:nextmonth": "Prochain mois",
  "calendars:nextyear": "Prochaine année",
  "calendars:previousyearrange": "Séries de l'année suivante",
  "calendars:previousmonthrange": "Séries de l'année précédente",
  "global:export:wait":
    "Veuillez patienter pendant que nous générons votre document.",
  "country:Canada": "Canada",
  "country:Mexique": "Mexique",
  "country:Guatemala": "Guatemala",
  "country:Jamaïque": "Jamaïque",
  "country:Honduras": "Honduras",
  "country:Grenade": "Grenade",
  "country:Sainte Lucie": "Sainte Lucie",
  "country:Salvador": "Salvador",
  "country:Trinidad et Tobago": "Trinidad et Tobago",
  "country:Barbades": "Barbade",
  "country:Panama": "Panama",
  "country:Costa Rica": "Costa Rica",
  "country:Antilles": "Antilles",
  "country:République Dominicaine": "République Dominicaine",
  "country:Nicaragua": "Nicaragua",
  "country:Saint-Vincent": "Saint-Vincent",
  "country:États-Unis": "États-Unis",
  "country:Tunisie": "Tunisie",
  "country:France": "France",
  "country:Italie": "Italie",
  "country:Algérie": "Algérie",
  "country:Madagascar": "Madagascar",
  "country:Colombie": "Colombie",
  "country:Maroc": "Maroc",

  "dialog:error:title": "Erreur",
  "dialog:error:moneris": "Une erreur s'est produite avec Moneris.",
};
